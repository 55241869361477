@import "~animate.css/animate.min";

/*Importar fuentes custom*/
@font-face {
  font-family: "yanone";
  src: url("fonts/yanone.ttf");
}

@font-face {
  font-family: "Finger";
  src: url("fonts/finger.ttf");
}

@font-face {
  font-family: "BadScript";
  src: url("fonts/BadScript-Regular.ttf");
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("fonts/RobotoCondensed-Light.ttf");
}

@font-face {
  font-family: "Great Vibes";
  src: url("fonts/GreatVibes-Regular.ttf");
}


@font-face {
  font-family: "Loved";
  src: url("fonts/LovedbytheKing-Regular.ttf");
}

@font-face {
  font-family: "Caveat";
  src: url("fonts/Caveat-VariableFont_wght.ttf");
}

@font-face {
  font-family: "KaushanScript";
  src: url("fonts/KaushanScript-Regular.ttf");
}

@font-face {
  font-family: "PTSans-Italic";
  src: url("fonts/PTSans-Italic.ttf");
}




/*Generales*/
* {
  margin: 0px;
  padding: 0px;
}

/*
body{
    font-family: "yanone", Arial, Helvetica;
    font-size:20px;
    background: url("../img/pattern.png");
    background-attachment: fixed;
}
*/
.clearfix {
  float: none;
  clear: both;
}

/*Barra de navegación / Cabecera */

header {
  width: 100%;
  background-color: #dae2d8;
  font-size: 15px;
  text-align: center;
}

header ul li {
  margin: 0px auto;
  text-align: center;

  font-family: "Roboto Condensed";
  text-transform: uppercase;
}

header a {
  display: block;
  padding-right: 90px;
  padding-left: 30px;
  height: inherit;
  color: #024245 !important;
  font-weight: bold;
}

/* Logotipo */

.logo {
  padding: 15px 15px 55px;
  background-color: #363636 !important;
  float: left;
  position: absolute;
}

.navbar-header {
  float: left;
  padding: 15px;
  text-align: center;
  width: 100%;
}

.navbar .container {
  justify-content: flex-end !important;
}

.navbar-brand {
  float: none;
}

#logo {
  margin-top: 10px;
  font-weight: 400;
  word-wrap: break-word;
  font-family: "BadScript", cursive;
  font-size: 1em;
  line-height: 36px;

  color: #024245;
  /*  letter-spacing: 7px;*/
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  #logo {
    margin-top: 10px;
    font-weight: 400;
    word-wrap: break-word;
    font-family: "BadScript", cursive;
    font-size: 1em;
    line-height: 36px;

    color: #024245;
    /*  letter-spacing: 7px;*/
  }

  footer {
    text-align: center;
  }

  .footer-social {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center !important;
  }

  .footer-links {
    padding-top: 20px;
  }

  .footer-contact {
    padding-top: 20px;
  }

  .photo-gallery-item a img {
    width: 100%;
  }

  .carousel > button:first-of-type {
    left: 20px !important;
  }
  
  .carousel > button:last-of-type {
    right: 60px !important;
  }


  header a {
    padding-right: 30px;
    padding-left: 30px;
  }

}

.navbar-nav a {
  line-height: 1rem;

  background-color: #dae2d8 !important;
}

.navbar-nav .active a,
.navbar-nav a:hover {
  color: darkorange !important;

  background: transparent;
  border-bottom: 3px solid darkorange;

  background-color: #dae2d8 i !important;
}

.tagline {
  font-size: 0.5em;
  color: rgb(98, 107, 98);
  font-family: "BadScript", cursive;
}

/*Contenedor principal*/

#content {
  min-height: 500px;
  width: 100% !important;
  margin: 0px auto;
}

body,
html {
  position: relative;
  font-family: "Calibre", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

img {
  vertical-align: middle;
  border-style: none;
}


*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "PTSans-Italic", "Helvetica Neue", sans-serif !important ;
}

.carousel-indicators {
  left: 0% !important;
  transform: translateX(0%) !important;
}

h3 {
  font-size: 22px !important;
  line-height: 30px !important;
  font-style: italic !important;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  
  font-size: 14px;
}


element.style {
  visibility: visible;
  animation-name: fadeIn;
}

.fa,
.fas {
  font-weight: 900;
}

@media only screen and (min-width: 48em) {
  .box-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
  }
}

.box-container .image-box {
  flex: 1;
}

.box-container .content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 220px;
}




img {
  max-width: 100%;
  height: auto;
}

.row {
  /*
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;*/
  margin-right: 0px;
}



.button-up:hover{
  transform: scale(1.1);
  border-color: rgba(0, 0, 0, 0.1)
}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  opacity: 80%;
  color: white;
}

.material-icons{
  font-size: 60px !important;
}


.carousel > button:first-of-type {
  left: 5rem !important;
}

.carousel > button:last-of-type {
  right: 5rem !important;
}



.font-cursive{
  font-family: "Great Vibes" !important;
  font-size: 50px !important;
  line-height:60px !important;
}

.font-loved{
  font-family: "Loved" !important;
  font-size: 50px !important;
  line-height:70px !important;
}




.mat-tab-group {
  margin-bottom: 48px;
}

.mat-tab-header {
  background-color: #024245 !important;
}

.mat-tab-label .mat-tab-label-content {
  color: white !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: darkorange;
}
.rooms-main {
  width: 100%;
}

.rooms-all {
  width: 100%;
}
